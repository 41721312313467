import "./App.css";
import { Main } from "./Main";

function App() {
  return (
    <div>
      <Main></Main>
    </div>
  );
}

export default App;
